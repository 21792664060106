import React from "react";
import Layout from "../../components/Layout";
import { graphql, Link } from "gatsby";

export default function Index({ data, location }) {
  const patterns = data.allContentfulPatterns.nodes;

  return (
    <Layout
      location={location}
      breadcrumb1="Home"
      breadcrumb2="Patterns"
      title="Patterns"
      description="These are the higher order, repeatable ways of using the Nielsen Design System components. Patterns may help give you an idea of how to use the components or construct other larger-scale designs."
    >
      <div className="styled-grid">
        <div
          className="styled-grid gds-space-500"
          style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
        >
          <table class="styled-table-large">
            <tr>
              <th style={{ width: "25%" }}>Pattern Name</th>
              <th>Description</th>
              <th style={{ width: "20%" }} className="last">
                Status
              </th>
            </tr>
            {patterns.map((pattern) => (
              <tr>
                <td>{getPattern(pattern)}</td>
                <td>{pattern.description}</td>
                <td style={{ textAlign: "center" }}>{getStatus(pattern)}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Layout>
  );
  function getPattern(pattern) {
    //if (pattern.status === "Planned") return null;
    return (
      <React.Fragment>
        <Link
          to={"/patterns/" + pattern.name.toLowerCase().replace(/\s/g, "-")}
          activeClassName="active"
          key={pattern.id}
        >
          <gds-link size="400" weight="bold">
            {pattern.name}
          </gds-link>
        </Link>
      </React.Fragment>
    );
  }

  function getStatus(pattern) {
    if (pattern.status === "Planned")
      return <gds-chip color="gray">{pattern.status}</gds-chip>;
    else if (pattern.status === "In Progress")
      return <gds-chip color="blue">{pattern.status}</gds-chip>;
    else if (pattern.status === "Ready")
      return <gds-chip color="green">{pattern.status}</gds-chip>;
  }
}

//export components list query
export const query = graphql`
  query PatternList {
    allContentfulPatterns(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        status
        description
      }
    }
  }
`;
